import getRpcUrl from "utils/getRpcUrl";

const RPC_URL = getRpcUrl();
const network = {
  chainId: 8453,
  name: "ETH Smart Chain",
  ensAddress: undefined,
};
const httpProvider = null;

export default httpProvider;
