import { getMulticallContract } from "utils/contractHelpers";
import httpProvider from "./providerHelpers";
// Addresses
const multicall = async (abi, calls) => {
  try {
    return null;
  } catch (error) {
    throw new Error(error);
  }
};

export default multicall;
